// 员工借款
<template>

    <div class="employeeBorrowMoney baseBg">

        <div class="listBox mainWhiteBg">
            <el-tabs v-model="searchForm.queryFlag"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="getList(1)">
                <el-tab-pane name="1"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_报销_员工借款_待核销')">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,'Tab_报销_员工借款_待核销')"
                         slot="label">
                        <el-badge :type="activeName == '1' ? 'primary' : 'warning'"
                                  :value="counts['1']"
                                  class="item">
                            待核销
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="2"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_报销_员工借款_已核销')">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,'Tab_报销_员工借款_已核销')"
                         slot="label">
                        已核销
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">

                        <el-form-item label="付款编号"
                                      prop="code">
                            <el-input v-model="searchForm.code"
                                      placeholder="请输入付款编号"
                                      clearable></el-input>
                        </el-form-item>

                        <el-form-item label="部门"
                                      prop="deptId">
                            <dept-select-tree ref="selectDepTree"
                                              placeholderStr="请选择部门"
                                              :needClean="needClean"
                                              :shouldClean="true"
                                              @handClear="clearDept"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>

                        <el-form-item label="日期"
                                      prop="dates">
                            <el-date-picker v-model="searchForm.dates"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-button type="primary"
                                   @click="getList(1)">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>

                        <com-export-table-to-excel class="flr"
                                                   :searchForm="searchForm"></com-export-table-to-excel>

                    </el-form>
                </div>
                <div class="tableBox">

                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              tooltip-effect="dark"
                              stripe>
                        <el-table-column label="序号"
                                         type="index"
                                         align="center"
                                         width="60">
                        </el-table-column>
                        <el-table-column label="日期"
                                         prop="date"
                                         align="center"
                                         width="128">
                        </el-table-column>
                        <el-table-column prop="code"
                                         label="编号"
                                         align="left"
                                         min-width="auto"
                                         width="160">
                        </el-table-column>
                        <el-table-column prop="applicationName"
                                         label="职员"
                                         align="left"
                                         min-width="auto"
                                         width="95">
                        </el-table-column>
                        <el-table-column prop="deptName"
                                         label="部门"
                                         align="left"
                                         min-width="auto"
                                         width="100">
                        </el-table-column>
                        <el-table-column prop="amount"
                                         label="借款金额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="balance"
                                         label="借款余额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="remark"
                                         label="备注"
                                         align="left"
                                         min-width="220"
                                         width="auto">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.remark">
                                    <p class="ellipsis"
                                       v-if="scope.row.remark">{{scope.row.remark}}</p>
                                    <span v-else>暂无</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         fixed="right"
                                         width="85">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           size="mini"
                                           @click="openpopPayHistory(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pageFooter">
                        <!-- 金额统计 -->
                        <amount-statistical type="员工借支"
                                            :totalData="statistical"></amount-statistical>
                        <!-- 分页 -->
                        <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" />
                    </div>

                </div>
            </div>
        </div>

        <!-- 收款记录详情 -->
        <pop-pay-history v-if="popPayHistoryVisible"
                         :id="detailsRow.id"
                         incomeOrPay="pay"
                         @close="closepopPayHistory"></pop-pay-history>

    </div>

</template>

<script>
import AmountStatistical from "../../components/amountStatistical/amountStatistical";
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import PopPayHistory from "../moneyManage/components/popPayHistory";
import DeptSelectTree from "../../components/selectTree/deptSelectTree";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "employeeBorrowMoney",

    props: {},

    components: {
        AmountStatistical,
        ComExportTableToExcel,
        PopPayHistory,
        DeptSelectTree,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                queryFlag: "1", // tabs 1=待核销,2=已核销
                dates: [], // 日期
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,

            tableData: {
                data: [],
            },
            // tabs对应文字
            tabsName: ["待核销", "已核销"],
            counts: {
                1: 0, // 待核销 数量
                2: 0, // 已核销 数量
            },

            opts: [
                {
                    value: "",
                    label: "全部",
                },
            ],
            cleanProvider: false, //清空客户供应商
            popPayHistoryVisible: false, // 收款记录详情弹窗显示
            detailRole: {}, //权限数据
            statistical: null, // 统计信息
        };
    },

    created() {},

    mounted() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_报销_员工借款_待核销",
            "Tab_报销_员工借款_已核销",
        ]);
        // 选中第一个tab
        this.searchForm.queryFlag = this.$refs.searchTab.$children[1].name;
        // 列表数量
        this.getEmployeeBorrowMoneyListCount();
        // 获取列表
        this.getList(1);
    },

    methods: {
        // 列表数量
        getEmployeeBorrowMoneyListCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-tabs"),
            });
            API.getEmployeeBorrowMoneyListCount()
                .then((res) => {
                    this.counts = {
                        1: res.content, // 待核销 数量
                    };
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取列表
        getList(page) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            this.searchForm.flag = [false, true][this.searchForm.queryFlag - 1]; // 核销标记 true=已核销 false=待核销
            let data = Object.assign({}, this.searchForm, {
                page: page || this.searchForm.page,
            });
            API.getEmployeeBorrowMoneyList(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    this.counts[this.searchForm.queryFlag] = res.content.total;
                    this.statistical = res.content.statistical;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除员工借款
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delemployeeBorrowMoney(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        //部门的选择
        departmentClick(node) {
            this.searchForm.deptId = node.id;
        },
        //部门清空
        clearDept() {
            this.searchForm.deptId = "";
            this.$refs.selectDepTree.cleanSelect();
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.searchForm.projectId = ""; // 清空关联项目
                    //部门清空
                    this.clearDept();
                    // 查询列表
                    this.getList(1);
                }
            });
        },
        // 收款记录详情 打开弹框
        openpopPayHistory(item) {
            this.detailsRow = item || null;
            this.popPayHistoryVisible = true;
        },
        // 收款记录详情 关闭弹框
        closepopPayHistory() {
            this.detailsRow = null;
            this.popPayHistoryVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 285 });
        },
        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "员工借款_列表"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .employeeBorrowMoney{

// }
</style>
