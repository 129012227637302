// 收款记录详情
// popPayHistory
<template>

    <div class="popPayHistory">
        <el-dialog :title="`${detailsInfo.type.text}记录详情`"
                   :close-on-click-modal="false"
                   modal-append-to-body="true"
                   append-to-body="true"
                   :visible.sync="dialogVisible"
                   width="1000px"
                   :before-close="close">

            <div class="infoBox mb20">
                <el-row>
                    <el-col :span="12">
                        <span class="label">业务{{incomeOrPay === "pay" ? "付款" : "收款"}} </span>
                        <span class="value">{{detailsInfo.type.text || "无"}}</span>
                    </el-col>
                    <el-col :span="12"
                            v-if="detailsInfo.type">
                        <span class="label">{{incomeOrPay === "pay" ? "付款" : "收款"}}账户</span>
                        <span class="value">{{detailsInfo.account || "无"}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12"
                            v-if="detailsInfo.type">
                        <span class="label">{{incomeOrPay === "pay" ? "付款" : "收款"}}金额</span>
                        <span class="value">{{detailsInfo.amount || "无"}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span class="label">{{incomeOrPay === "pay" ? "付款" : "收款"}}日期</span>
                        <span class="value">{{detailsInfo.payDate || "无"}}</span>
                    </el-col>
                </el-row>
            </div>

            <div class="popItemBox">
                <el-tabs type="border-card">
                    <el-tab-pane :label="`关联${incomeOrPay === 'pay' ? '付款' : '收款'}单`">
                        <div class="tableBox">
                            <el-table :data="[detailsInfo.payment]"
                                      border
                                      stripe>
                                <el-table-column prop="createTime"
                                                 label="日期"
                                                 align="left"
                                                 min-width="auto"
                                                 width="128">
                                </el-table-column>
                                <!-- TODO 编号需要可以点击  -->
                                <el-table-column prop="busCode"
                                                 label="编号"
                                                 align="left"
                                                 min-width="auto"
                                                 width="160">
                                </el-table-column>
                                <el-table-column prop="companyName"
                                                 label="客户/供应商"
                                                 align="left"
                                                 min-width="auto"
                                                 width="118">
                                </el-table-column>
                                <el-table-column prop="projectName"
                                                 label="项目"
                                                 align="left"
                                                 min-width="auto"
                                                 width="auto">
                                </el-table-column>
                                <el-table-column prop="amount"
                                                 :label="`本次${incomeOrPay === 'pay' ? '付款' : '收款'}`"
                                                 align="left"
                                                 min-width="auto"
                                                 width="108">
                                </el-table-column>
                                <el-table-column prop="balance"
                                                 label="余额（元）"
                                                 align="left"
                                                 min-width="auto"
                                                 width="108">
                                </el-table-column>
                                <el-table-column prop="remark"
                                                 label="备注"
                                                 align="left"
                                                 min-width="auto"
                                                 width="220">
                                    <template slot-scope="scope">
                                        <el-tooltip class="item"
                                                    effect="dark"
                                                    :content="scope.row.remark">
                                            <p class="ellipsis"
                                               v-if="scope.row.remark">{{scope.row.remark}}</p>
                                            <span v-else>暂无</span>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-tab-pane>
                    <!-- 员工借支付款类型 expenditureFile -->
                    <el-tab-pane label="附件">
                        <template v-if="detailsInfo.type.text === '员工借支'">
                            <com-file-upload-list fileBoxHeight="250px"
                                                  :showUpBtn="false"
                                                  :uploadData="{ resourceId: detailsInfo.id, resourceType: 'expenditureFile'}"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="false"
                                                  :isNeedPopTemDownload="false"
                                                  :showDownloadBtn="true"
                                                  :showDelBtn="true"></com-file-upload-list>
                        </template>
                        <template v-else>
                            <com-file-upload-list fileBoxHeight="250px"
                                                  :showUpBtn="false"
                                                  :uploadData="{ resourceId: detailsInfo.id, resourceType:  detailsInfo.payment.type.text === '付款' ? 'expenditureFile' : 'incomeFile'}"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="false"
                                                  :isNeedPopTemDownload="false"
                                                  :showDownloadBtn="true"
                                                  :showDelBtn="true"></com-file-upload-list>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <p class="footerInfo">
                <span>{{incomeOrPay === "pay" ? "付款" : "收款"}}操作人：{{detailsInfo.operatorName || "无"}}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>操作时间：{{detailsInfo.operatorTime || "无"}}</span>
            </p>

            <div class="footer tar pt0"
                 v-if="detailsInfo.type.text !== '员工借支'">
                <el-popconfirm confirm-button-text="删除"
                               cancel-button-text="取消"
                               icon="el-icon-info"
                               icon-color="red"
                               @confirm="del"
                               title="真的要删除吗？">
                    <el-button type="danger"
                               size="mini"
                               slot="reference">删除</el-button>
                </el-popconfirm>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/finance";
export default {
    name: "popPayHistory",

    props: {
        // 查询详情用弹窗
        id: {
            type: String,
            default: null,
        },
        // 是支出还是收款
        incomeOrPay: {
            type: String,
            default: "income",
        },
    },

    components: {
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,
            detailsInfo: {}, // 详情内容
        };
    },

    created() {},

    mounted() {
        // 获取详情
        this.getPayHisDetails();
    },

    methods: {
        // 获取详情
        getPayHisDetails() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getPayHisDetails(this.id)
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    // 这里很奇怪，列表的金额和余额取的都是父级的不是payment中的
                    data.payment.amount = data.amount;
                    data.payment.balance = data.balance;
                    this.detailsInfo = data;
                })
                .catch(() => {
                    loading.close();
                    this.close();
                });
        },
        // 删除
        del() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delCashierCollectionRecord(this.id)
                .then(() => {
                    loading.close();
                    this.$emit("update");
                    this.$message({ message: "操作成功！", type: "success" });
                    this.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding: 20px;
}
.infoBox {
    padding-right: 0;
    border-bottom: 1px solid #f2f5f8;
    span {
        display: inline-block;
        line-height: 50px;
    }
    .label {
        width: 128px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }
    .el-row {
        padding: 0 20px;
    }
    .el-row:nth-of-type(odd) {
        background: #f2f5f8;
    }
}
.footerInfo {
    font-size: 14px;
    color: #666666;
}
</style>